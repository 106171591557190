import React from 'react';
import { FaHandHoldingHeart, FaMedkit, FaAmbulance, FaHandHoldingUsd, FaCalendarAlt, FaHeartBroken, FaBaby, FaTools } from 'react-icons/fa';

const Services = () => {
  const services = [
    { title: 'BPC Loas', description: 'Para pessoa com deficiência e idoso.', icon: <FaHandHoldingHeart className="text-[#C08E4D] text-3xl" /> },
    { title: 'Auxílio Doença', description: 'Para pessoas que por motivo de doença ou acidente, ficam temporariamente incapazes de exercer suas atividades laborais.', icon: <FaMedkit className="text-[#C08E4D] text-3xl" /> },
    { title: 'Auxílio Acidente', description: 'Indenização, para pessoas que sofreram acidente de qualquer natureza.', icon: <FaAmbulance className="text-[#C08E4D] text-3xl" /> },
    { title: 'Aposentadoria', description: 'Por Incapacidade, Deficiência, Idade, Tempo de Contribuição e Especial.', icon: <FaHandHoldingUsd className="text-[#C08E4D] text-3xl" /> },
    { title: 'Pensão por Morte', description: 'Para cônjuge, filhos ou dependentes.', icon: <FaHeartBroken className="text-[#C08E4D] text-3xl" /> },
    { title: 'Salário Maternidade', description: 'Para à segurada gestante, adotante ou que tenha realizado aborto não criminoso.', icon: <FaBaby className="text-[#C08E4D] text-3xl" /> },
    { title: 'Serviços diversos', description: 'Consultoria, Cálculos, Análises com parecer/relatório, Planejamento Previdenciário e Mandado de Segurança.', icon: <FaTools className="text-[#C08E4D] text-3xl" /> }
  ];

  return (
    <section 
      id="servicos" 
      className="py-12 bg-gray-100 bg-cover bg-center relative"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/escritorio.png)` }}
    >
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Transparência da imagem */}
      <div className="container mx-auto relative z-10">
        <h2 className="text-3xl font-bold text-center mb-8 text-white">Serviços Prestados</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md flex items-center space-x-4">
              <div className="text-[#C08E4D]">
                {service.icon}
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
