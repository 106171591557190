import React from 'react';
import { FaInstagram, FaFacebookF, FaTiktok } from 'react-icons/fa';

const Header = () => {
  return (
    <header className="bg-gray-800 text-white fixed w-full top-0 z-50 shadow-lg">
      <div className="container mx-auto px-4 py-4 md:py-6">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <div className="flex items-center">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="Logo"
              className="h-10 md:h-12 w-auto"
            />
          </div>

          {/* Menu de Navegação - Escondido em telas pequenas */}
          <nav className="hidden md:flex">
            <ul className="flex space-x-6">
              <li><a href="#home" className="hover:text-gray-300 transition duration-300">Home</a></li>
              <li><a href="#sobre" className="hover:text-gray-300 transition duration-300">Sobre</a></li>
              <li><a href="#servicos" className="hover:text-gray-300 transition duration-300">Serviços</a></li>
              <li><a href="#contato" className="hover:text-gray-300 transition duration-300">Contato</a></li>
            </ul>
          </nav>

          {/* Ícones de Redes Sociais */}
          <div className="flex space-x-4">
            <a href="https://www.instagram.com/marianasoliver.adv" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300 transition duration-300">
              <FaInstagram size={24} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100065612704342" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300 transition duration-300">
              <FaFacebookF size={24} />
            </a>
            <a href="https://www.tiktok.com/@marianaadv196830" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300 transition duration-300">
              <FaTiktok size={24} />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
