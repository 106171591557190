import React from 'react';

const HelpServices = () => {
  const services = [
    'Aposentadoria por idade',
    'Aposentadoria por tempo de contribuição',
    'Aposentadoria por incapacidade permanente (invalidez)',
    'Auxílio por Incapacidade Temporária (auxílio-doença)',
    'Planejamento previdenciário',
    'Benefício de Prestação Continuada ao Portador de Deficiência/LOAS',
    'Benefício de Prestação Continuada ao Idoso/LOAS',
    'Salário Maternidade',
    'Cálculo de benefício',
    'Revisões de Aposentadorias'
  ];

  return (
    <section 
      id="home" 
      className="py-16 bg-cover bg-center relative mt-24"
      style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/images/deusa.png)` }}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div> {/* Camada de transparência */}
      <div className="container mx-auto px-4 relative z-10 text-center">
        {/* Título e subtítulo */}
        <h2 className="text-4xl font-bold text-white mb-4">Advogada Previdenciarista</h2>
        <p className="text-xl text-gray-200 mb-10">Em que posso ajudar?</p>

        {/* Lista de serviços com efeito de hover */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service, index) => (
            <div 
              key={index} 
              className="bg-white p-6 rounded-lg shadow-md transition duration-300 transform hover:bg-gray-200 hover:text-[#C08E4D] hover:scale-105"
            >
              <h3 className="text-lg font-semibold">{service}</h3>
            </div>
          ))}
        </div>

      </div>
    </section>
  );
};

export default HelpServices;
