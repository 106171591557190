import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const FloatingWhatsappButton = () => {
  const phoneNumber = '+5534992490332';
  const message = 'Olá, gostaria de saber mais sobre os seus serviços!';
  
  return (
    <a
      href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
      target="_blank"
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 bg-green-500 text-white rounded-full p-4 shadow-lg hover:bg-green-600 transition duration-300"
      style={{ zIndex: 1000 }}
    >
      <FaWhatsapp size={30} /> {/* Aumente o tamanho do ícone */}
    </a>
  );
};

export default FloatingWhatsappButton;
