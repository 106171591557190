import React from 'react';
import { FaPhone, FaEnvelope, FaCalendarAlt, FaInstagram, FaFacebookF, FaTiktok } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer id="contato" className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          {/* Seção de Contato */}
          <div className="flex flex-col items-center md:items-start md:w-1/3 mb-8 md:mb-0">
            <h3 className="text-xl font-semibold mb-4">Contato</h3>
            <div className="flex items-center mb-4">
              <FaPhone className="text-xl mr-3" />
              <p>(34) 99249-0332</p>
            </div>
            <div className="flex items-center mb-4">
              <FaEnvelope className="text-xl mr-3" />
              <p>marianasoliver.adv@gmail.com</p>
            </div>
            <div className="flex items-center">
              <FaCalendarAlt className="text-xl mr-3" />
              <p>Seg-Sex 08hrs às 18hrs</p>
            </div>
          </div>

          {/* Imagem no meio */}
          <div className="flex justify-center md:w-1/3 mb-8 md:mb-0">
            <img 
              src={`${process.env.PUBLIC_URL}/images/logo.png`} 
              alt="Logo" 
              className="w-auto h-12"
            />
          </div>

          {/* Seção de Redes Sociais */}
          <div className="flex flex-col items-center md:items-end md:w-1/3">
            <h3 className="text-xl font-semibold mb-4">Redes Sociais</h3>
            <div className="flex flex-col items-center space-y-4">
              <a 
                href="https://www.instagram.com/marianasoliver.adv" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center text-lg hover:text-gray-300 transition duration-300"
              >
                <FaInstagram className="text-2xl mr-3" />
                Instagram
              </a>
              <a 
                href="https://www.facebook.com/profile.php?id=100065612704342" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center text-lg hover:text-gray-300 transition duration-300"
              >
                <FaFacebookF className="text-2xl mr-3" />
                Facebook
              </a>
              <a 
                href="https://www.tiktok.com/@marianaadv196830" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="flex items-center text-lg hover:text-gray-300 transition duration-300"
              >
                <FaTiktok className="text-2xl mr-3" />
                TikTok
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-center">
          <p>&copy; 2024 Mariana Silva de Oliveira. Todos os direitos reservados.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
