import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const FAQ = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const questions = [
    {
      question: "O que é aposentadoria por invalidez e quem tem direito?",
      answer: "A aposentadoria por invalidez é um benefício concedido ao segurado que, por doença ou acidente, está permanentemente incapacitado de exercer qualquer atividade profissional. Para ter direito, é necessário passar por uma perícia médica do INSS, que avaliará a incapacidade. Além disso, o segurado deve ter contribuído com o INSS por, no mínimo, 12 meses, salvo nos casos de acidente de trabalho ou doença grave."
    },
    {
      question: "Como funciona o cálculo da aposentadoria por tempo de contribuição?",
      answer: "O cálculo da aposentadoria por tempo de contribuição leva em consideração o tempo total de contribuições ao INSS e a média dos salários de contribuição ao longo da vida profissional do segurado. Com a Reforma da Previdência, o tempo mínimo de contribuição é de 35 anos para homens e 30 anos para mulheres."
    },
    {
      question: "O que é o benefício de pensão por morte e quem pode recebê-lo?",
      answer: "A pensão por morte é um benefício pago aos dependentes do segurado que falece, desde que ele estivesse contribuindo para o INSS ou já recebendo algum benefício previdenciário na data do falecimento."
    },
    {
      question: "Quais são os requisitos para obter o auxílio-doença?",
      answer: "O auxílio-doença é concedido ao segurado que, por motivo de doença ou acidente, está temporariamente incapacitado para o trabalho. Para ter direito ao benefício, é necessário ter contribuído com o INSS por, no mínimo, 12 meses. Exceto nos casos de doença grave que estejam previstas na Lei 7.713, de julho de de dezembro de 1988."
    },
    {
      question: "Como posso solicitar a revisão do benefício de aposentadoria?",
      answer: "A revisão do benefício pode ser solicitada quando o segurado identifica erros no cálculo do valor ou no tempo de contribuição considerado pelo INSS. Para solicitar a revisão, é necessário entrar com uma petição no INSS ou judicialmente."
    }
  ];

  return (
    <div className="faq-container max-w-2xl mx-auto mt-10 p-6">
      <h2 className="text-3xl font-bold text-center mb-6 text-black">Perguntas Frequentes</h2>
      {questions.map((item, index) => (
        <div key={index} className="mb-6">
          <div
            onClick={() => toggleQuestion(index)}
            className="faq-question cursor-pointer text-lg font-medium text-gray-800 flex justify-between items-center bg-gray-100 p-4 shadow-lg rounded-lg hover:text-[#C08E4D] transition duration-300"
          >
            <span>{item.question}</span>
            {openQuestion === index ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          <div
            className={`faq-answer mt-2 text-gray-600 transition-max-height duration-500 ease-in-out overflow-hidden ${openQuestion === index ? 'max-h-40' : 'max-h-0'}`}
          >
            {openQuestion === index && <p className="p-4">{item.answer}</p>}
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;
