import React from 'react';
import Header from './components/Header';
import HelpServices from './components/HelpServices';
import About from './components/About';
import Services from './components/Services';
import Diferenciais from './components/Diferenciais';
import Faq from './components/FAQ';
import Footer from './components/Footer';
import FloatingWhatsappButton from './components/BotaoFlutuante'; // Importe o botão flutuante


function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <HelpServices />
        <About />
        <Diferenciais />
        <Services />
        <Faq />
        <FloatingWhatsappButton /> {/* Inclua o botão flutuante */}
      </main>
      <Footer />
    </div>
  );
}

export default App;