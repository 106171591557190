import React from 'react';

const About = () => {
  return (
    <section id="sobre" className="py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-gray-800">
          Sobre a Dra. Mariana Silva de Oliveira
        </h2>
        <div className="flex flex-col md:flex-row items-center md:items-start space-y-8 md:space-y-0 md:space-x-12">
          <div className="md:w-2/3 bg-white p-8 rounded-lg shadow-lg border border-gray-200">
            <p className="text-gray-700 mb-4">
              <strong>Mariana Oliveira</strong> é uma advogada especializada em <strong>Direito Previdenciário</strong>, uma área do direito que lida com questões relacionadas à seguridade social, aposentadorias, pensões e outros benefícios oferecidos pelo sistema previdenciário brasileiro. Com uma sólida formação acadêmica e anos de experiência prática, Mariana desenvolveu uma reputação como uma profissional competente e dedicada, reconhecida por sua capacidade de navegar nas complexidades do sistema previdenciário com eficácia e precisão.
            </p>
            <p className="text-gray-700 mb-4">
              Sua atuação como advogada previdenciarista envolve a representação de clientes em diversas fases do processo, desde o requerimento inicial de benefícios até a defesa em casos de indeferimento de pedidos pelo Instituto Nacional do Seguro Social (INSS). Mariana é conhecida por sua abordagem empática, sempre buscando entender as necessidades e preocupações de seus clientes, muitos dos quais são pessoas que dependem do sucesso de seus processos para garantir sua subsistência e qualidade de vida.
            </p>
            <p className="text-gray-700">
              Seu compromisso com a justiça social e sua dedicação em garantir que seus clientes recebam os benefícios a que têm direito fazem dela uma referência em sua área de atuação.
            </p>
          </div>
          <div className="md:w-1/3 flex flex-col items-center">
            <img 
              src={`${process.env.PUBLIC_URL}/images/mariana1.jpeg`}
              alt="Dra. Mariana Silva de Oliveira" 
              className="w-48 h-48 object-cover rounded-full border-4 border-gray-300 shadow-lg mb-4" // Ajuste da imagem com borda e sombra
            />
            <div className="text-center">
              <p className="font-bold text-xl text-gray-800 mb-1">Mariana Silva de Oliveira</p>
              <p className="text-gray-700 mb-1">Advogada</p>
              <p className="text-gray-600 mb-6">OAB/MG 196833</p>
              <a 
                href="https://wa.me/+5534992490332" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="inline-flex items-center bg-[#C08E4D] text-white text-lg font-semibold px-6 py-3 rounded-lg shadow-md hover:bg-[#a67e3c] transition duration-300 transform hover:scale-105"
              >
                Falar com Advogado
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
