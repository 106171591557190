import React from 'react';
import { FaMapMarkerAlt, FaGlobe, FaLock } from 'react-icons/fa';

const Diferenciais = () => {
  return (
    <div className="diferenciais-container max-w-7xl mx-auto mt-12 p-6 text-center">
      <div style={{ height: '50px' }}></div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        
        {/* Item 1: Atendimento Presencial e Online */}
        <div className="diferencial-item flex flex-col items-center mb-6 md:mb-0">
          <FaMapMarkerAlt className="text-5xl" style={{ color: '#C08E4D' }} />
          <h3 className="text-2xl font-bold mt-4">Atendimento Presencial e Online</h3>
          <p className="text-gray-600 mt-2">
            Flexibilidade para atender presencialmente ou virtualmente.
          </p>
        </div>

        {/* Divider */}
        <div className="divider h-px w-full md:w-px bg-gray-300 mb-6 md:mb-0 md:mx-8"></div>

        {/* Item 2: Representação Nacional */}
        <div className="diferencial-item flex flex-col items-center mb-6 md:mb-0">
          <FaGlobe className="text-5xl" style={{ color: '#C08E4D' }} />
          <h3 className="text-2xl font-bold mt-4">Representação Nacional</h3>
          <p className="text-gray-600 mt-2">
            Atuação em diversas regiões do país.
          </p>
        </div>

        {/* Divider */}
        <div className="divider h-px w-full md:w-px bg-gray-300 mb-6 md:mb-0 md:mx-8"></div>

        {/* Item 3: Confiança e Discrição Garantidas */}
        <div className="diferencial-item flex flex-col items-center mb-6 md:mb-0">
          <FaLock className="text-5xl" style={{ color: '#C08E4D' }} />
          <h3 className="text-2xl font-bold mt-4">Confiança e Discrição Garantidas</h3>
          <p className="text-gray-600 mt-2">
            Reforçamos a confidencialidade dos seus casos.
          </p>
        </div>
      </div>
      <div style={{ height: '100px' }}></div>
    </div>
  );
};

export default Diferenciais;
